window.onload = (event) => {

// Eventbrite
var request = new XMLHttpRequest();

request.open('GET', 'https://www.eventbriteapi.com/v3/events/629080736417/capacity_tier/');

request.setRequestHeader('Authorization', 'Bearer EGGWP3ZDKIVJHPZY2JCI');

request.onreadystatechange = function () {
  if (this.readyState === 4) {
    // console.log('Status:', this.status);
    // console.log('Headers:', this.getAllResponseHeaders());
    // console.log(this.responseText);
    let nbrMax =  this.responseText.slice( this.responseText.indexOf("capacity_total")  , this.responseText.indexOf("capacity_is_custom") );
    nbrMax = nbrMax.slice( nbrMax.indexOf(':') + 1 , nbrMax.lastIndexOf(',') );
    nbrMax = Number(nbrMax);
    
    let nbrSell =  this.responseText.slice( this.responseText.indexOf("quantity_sold")  , this.responseText.indexOf("quantity_total") );
    nbrSell = nbrSell.slice( nbrSell.indexOf(':') + 1 , nbrSell.lastIndexOf(',') );
    nbrSell = Number(nbrSell);
    
    $maxDePlaces = 60;
    $placesRestantes = 60 - nbrSell;
  }
};

request.send();


gsap.registerPlugin(ScrollTrigger);

// Var Counter Places


// Swiper Gallery - - - - - - - - - - -
const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'horizontal',
    speed: 1000,
    slidesPerView: 1,
    initialSlide: 2,
    centeredSlides: true,
    loop: true,

    breakpoints: {
        // when window width is >= 320px
        480: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        850: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        // when window width is >= 640px
        1300: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        1700: {
          slidesPerView: 3,
          spaceBetween: 20
        }
    },
  
    // If we need pagination
    // pagination: {
    //   el: '.swiper-pagination',
    // },
  
    // Navigation arrows
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
  
    // And if we need scrollbar
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    // },
});


const gallery = document.querySelector('.swiper').swiper;
const prev_btn = document.querySelector('.gallery_btn_prev');
const next_btn = document.querySelector('.gallery_btn_next');

prev_btn.addEventListener('click', () => {
    gallery.slidePrev();
});
next_btn.addEventListener('click', () => {
    gallery.slideNext();
});


// Eventbrite - - - - - - - - - - -
    // ********
    // Remove comment of this section to unlock eventbrite 

var callBackEventbrite = function() {
    console.log('Order complete!');
};

window.EBWidgets.createWidget({
    widgetType: 'checkout',
    eventId: '629080736417',
    modal: true,
    modalTriggerElementId: 'eventbrite-widget-modal-trigger-629080736417',
    onOrderComplete: callBackEventbrite
});

    // Remove this section to unlock eventbrite
    // ***
    // document.querySelectorAll('.cta_vv').forEach(button => {
    //     button.addEventListener('click', ()=>{
    //         alert('Coming soon ! ⏰')
    //     });
    // });

    // ********
    // Remove comment of this section to unlock eventbrite 


// Socials Icon - - - - - - - - - - -
const socials_link = document.querySelectorAll('.socials_links');
const follow_text = document.querySelector('.footer_up__text');
const socials_hr = document.querySelector('.footer_up__hr');
const footer_container = document.querySelector('.footer_up');
socials_link.forEach(el => {
    el.addEventListener('mouseover', () => {
        follow_text.innerHTML = el.dataset.socials;
    });
    el.addEventListener('mouseleave', () => {
        follow_text.innerHTML = "Follow me : ";
    });

});


// Cursor - - - - - - - - - - -
const cursor = document.querySelector('.cursor_follow');
const cursor_center = document.querySelector('.cursor_follow_center');
document.addEventListener('mousemove', (e) => {
    gsap.to(cursor, {
        x: e.clientX - cursor.getBoundingClientRect().width / 2,
        y: e.clientY - cursor.getBoundingClientRect().height / 2,
        duration: .5,
        ease: 'power1.out'
    });
    gsap.to(cursor_center, {
        x: e.clientX - cursor_center.getBoundingClientRect().width / 2,
        y: e.clientY - cursor_center.getBoundingClientRect().height / 2,
        duration: .2,
        ease: 'power1.out'
    });
});

// Details Opacity - - - - - - - - - - -
const detailsItems = document.querySelectorAll('.details_content_items');
const detailsContainer = document.querySelector('.details');

detailsItems.forEach(element => {
    gsap.set(element, {
        y: 50,
        opacity: 0
    })
});
detailsItems.forEach(element => {
    gsap.to(element, {
        scrollTrigger: {
            // markers: true,
            trigger: element,
            start: "top 70%",
        },
        opacity: 1,
        y: 0,
        ease: "power4.out",
        duration: 1.5
    })
});

// Title Translatations - - - - - - - - - - -
const hero_title = document.querySelector('.header_content h1');
const hero_title_words = hero_title.querySelectorAll('*');

const end_title = document.querySelector('.end_content h6');
const end_container = document.querySelector('.end');

const naked_title = document.querySelector('.naked_content__title');
const naked_container = document.querySelector('.naked');

const tao_title = document.querySelector('.tao_content__title');
const tao_container = document.querySelector('.tao');

// Hero
gsap.set(hero_title, {
    x: -150,
    opacity: 0,
})
gsap.to(hero_title, {
    x: 1,
    opacity: 1,
    ease: "power4.out",
    duration: 2
})

// Naked
gsap.set(naked_title, {
    x: 150,
    opacity: 0,
})
gsap.to(naked_title, {
    x: 0,
    opacity: 1,
    ease: "power4.out",
    duration: 2,
    scrollTrigger: {
        trigger: naked_container,
        start: "top center",
    }
})

// Tao
gsap.set(tao_title, {
    x: 150,
    opacity: 0,
})
gsap.to(tao_title, {
    x: 0,
    opacity: 1,
    ease: "power4.out",
    duration: 2,
    scrollTrigger: {
        trigger: tao_container,
        start: "top 45%",
    }
})

// End
gsap.set(end_title, {
    x: 150,
    opacity: 0,
})
gsap.to(end_title, {
    x: -2,
    opacity: 1,
    ease: "power4.out",
    duration: 2,
    scrollTrigger: {
        trigger: end_container,
        start: "top center",
    }
})

// Airport - - - - - - - - - - -
const numberOfPlaces = document.querySelector('.number');
const airportPanel = document.querySelector('.letter');


setTimeout(() => {
    const state = {
        places: $maxDePlaces
    };
    const remaining = $placesRestantes;
    const delta = state.places - remaining
    gsap.to("#panneau", {
        rotateX: 360 * delta, 
        duration: 0.05 * delta, 
        ease: 'linear'
    })
    gsap.to(state, {
        places: remaining, 
        duration: 0.05 * delta, 
        ease: 'linear', 
        onUpdate: () => {
            numberOfPlaces.innerHTML = parseInt(state.places);
        }
    });
}, 2000);

};